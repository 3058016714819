import React from "react";
import styled from "styled-components";

import breakpoints from "../../utils/breakpoints";
import { grid, mapCompanySlugToLogo } from "../../utils/index";

const CompanyWrapper = styled.div`
  position: relative;
  background-color: #fffef8;
  padding: 1.6rem 2rem;
  border: 0.1rem solid #2b4463;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);

  @media ${breakpoints.tabletSm} {
    padding: 3.3rem 4.2rem 5.7rem;
  }

  @media ${breakpoints.tablet} {
    padding: 3.3rem 4.2rem 8.1rem;
  }

  @media ${breakpoints.desktopSm} {
    display: flex;
  }

  @media ${breakpoints.desktop} {
    padding: 8.2rem;
  }
`;

const CompanyInfo = styled.div`
  margin-bottom: 4rem;

  @media ${breakpoints.tabletSm} {
    margin-bottom: 10rem;
  }

  @media ${breakpoints.tablet} {
    margin-bottom: 8.4rem;
  }

  @media ${breakpoints.desktopSm} {
    display: block;
    margin-bottom: 0;
    width: ${grid(3, true)};
    flex: none;
  }

  @media ${breakpoints.desktop} {
    padding-right: 3.2rem;
  }
`;

const CompanyInfoContent = styled.div`
  @media ${breakpoints.tablet} {
    display: flex;
    align-items: center;
  }
  @media ${breakpoints.desktopSm} {
    display: block;
    position: sticky;
    top: 0;
  }
`;

const CompanyLogo = styled.div`
  margin: 2.4rem 0 4rem;

  @media ${breakpoints.tabletSm} {
    margin-top: 2.4rem;
    margin-bottom: 3rem;
  }

  @media ${breakpoints.tablet} {
    width: 38.21428571%;
    flex: none;
    padding-right: 3.5rem;
  }

  @media ${breakpoints.desktopSm} {
    width: 100%;
    margin-top: 3.9rem;
    margin-bottom: 8rem;
  }

  @media ${breakpoints.desktop} {
    margin-top: 0.5rem;
  }

  picture {
    display: block;
    position: relative;
    width: 50%;
    max-width: 13.3rem;

    @media ${breakpoints.tabletSm} {
      max-width: 15rem;
    }

    @media ${breakpoints.tablet} {
      max-width: 21rem;
      margin: 0 auto;
      width: 100%;
    }

    @media ${breakpoints.desktop} {
      max-width: 22.5rem;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;

      @media ${breakpoints.desktop} {
        max-width: 80%;
      }
    }
  }
`;

const CompanyInfoWrapper = styled.div`
  @media ${breakpoints.tablet} {
    padding-right: 7rem;
  }

  @media ${breakpoints.desktopSm} {
    padding-right: 0;
  }
`;

const CompanyDescription = styled.div`
  @media ${breakpoints.desktop} {
    font-size: 1.6rem;
    line-height: 1.3125;
  }

  p {
    margin-bottom: 2.5rem;

    @media ${breakpoints.desktopSm} {
      margin-bottom: 6rem;
    }
  }
`;

const CompanyLocation = styled.div`
  @media ${breakpoints.desktop} {
    font-size: 1.6rem;
    line-height: 1.3125;
  }

  p {
    margin-bottom: 0;
  }
`;

const CompanyUrl = styled.div`
  @media ${breakpoints.desktop} {
    font-size: 1.6rem;
    line-height: 1.3125;
  }

  p {
    margin-bottom: 0;
  }
`;

const JobsWrapper = styled.div`
  @media ${breakpoints.desktopSm} {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 3rem 3rem 0 7.3rem;
  }

  @media ${breakpoints.desktop} {
    padding-top: 0;
    padding-right: 0;
  }

  &[data-is-loading="true"] {
    @media ${breakpoints.desktop} {
      justify-content: center;
    }
  }
`;

const Job = styled.div`
  position: relative;
  padding: 2rem 0 2.5rem;
  border-top: 0.1rem solid #2b4463;

  @media ${breakpoints.tabletSm} {
    padding: 2.5rem 2rem 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media ${breakpoints.tablet} {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  @media ${breakpoints.desktop} {
    padding-left: 5.8rem;
    padding-right: 5.8rem;
  }

  &:last-child {
    border-bottom: 0.1rem solid #2b4463;
  }
`;

const JobInfo = styled.div`
  @media ${breakpoints.tabletSm} {
    padding-right: ${grid(1, true)};
  }
`;

const JobCTA = styled.div`
  display: none;

  @media ${breakpoints.tabletSm} {
    display: block;
  }

  a {
    display: block;
    width: 13.4rem;
    height: 4.3rem;
    text-align: center;
    line-height: 4rem;
    border: 0.1rem solid #2b4463;

    @media ${breakpoints.desktop} {
      font-size: 1.6rem;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

const JobTitle = styled.p`
  font-weight: 500;
  margin-bottom: 0;

  @media ${breakpoints.tabletSm} {
    font-size: 2.3rem;
    line-height: 1.65;
    font-weight: 200;
  }

  a:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const JobDate = styled.p`
  margin-bottom: 0;
  font-size: 1.6rem;
  line-height: 1.375;
`;

const JobLocation = styled.p`
  margin-bottom: 0;
  font-size: 1.6rem;
  line-height: 1.375;
`;

const Loading = styled.div`
  text-align: center;
`;

const Close = styled.button`
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.1rem;
  height: 2.1rem;

  @media ${breakpoints.tabletSm} {
    top: 3rem;
    right: 3rem;
  }

  @media ${breakpoints.tablet} {
    top: 2rem;
    right: 2rem;
  }

  @media ${breakpoints.desktop} {
    top: 3rem;
    right: 3rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const CompanyDetail = ({
  name,
  slug,
  logoUrl,
  description,
  domain,
  locations,
  jobs,
  loading,
  onCloseClick,
}) => {
  const logoSlug = mapCompanySlugToLogo(slug);

  return (
    <CompanyWrapper>
      <Close onClick={onCloseClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20.417"
          height="20.057"
          viewBox="0 0 20.417 20.057"
        >
          <g fill="none" stroke="#707070" stroke-width="1.5">
            <path data-name="Line 265" d="M1.032.528l18.852 19" />
            <path data-name="Line 266" d="M19.384.528l-18.852 19" />
          </g>
        </svg>
      </Close>
      <CompanyInfo>
        <CompanyInfoContent>
          <CompanyLogo>
            <picture>
              <img src={logoUrl} alt={name} />
            </picture>
          </CompanyLogo>
          <CompanyInfoWrapper>
            {description && (
              <CompanyDescription>
                <p>{description}</p>
              </CompanyDescription>
            )}
            <CompanyUrl>
              <p>
                <a href={`http://${domain}`} target="_blank" rel="noreferrer">
                  {domain}
                </a>
              </p>
            </CompanyUrl>
            {locations && (
              <CompanyLocation>
                {locations.map((location, i) => (
                  <p key={i}>{location}</p>
                ))}
              </CompanyLocation>
            )}
          </CompanyInfoWrapper>
        </CompanyInfoContent>
      </CompanyInfo>
      <JobsWrapper data-is-loading={loading}>
        {jobs && loading && <Loading>Loading...</Loading>}
        {jobs.length === 0 && !loading && (
          <Loading>There are currently no job openings for {name}</Loading>
        )}
        {jobs &&
          !loading &&
          jobs.map((job, i) => {
            const jobDate = new Date(job.created_at);

            return (
              <Job key={i}>
                <JobInfo>
                  <JobTitle>
                    <a href={job.url} target="_blank" rel="noreferrer">
                      {job.title}
                    </a>
                  </JobTitle>
                  <JobDate>{`Posted: ${jobDate.getDay()}/${jobDate.getMonth()}/${jobDate.getFullYear()}`}</JobDate>
                  {job.locations.length > 0 && (
                    <JobLocation>
                      {job.locations.map((location, i) => (
                        <span key={i}>{location}</span>
                      ))}
                    </JobLocation>
                  )}
                </JobInfo>
                <JobCTA>
                  <a href={job.url} target="_blank" rel="noreferrer">
                    Read More
                  </a>
                </JobCTA>
              </Job>
            );
          })}
      </JobsWrapper>
    </CompanyWrapper>
  );
};

export default CompanyDetail;
